// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Link, useHistory, useLocation } from 'react-router-dom';
// import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi, AdsPUTApiToken } from "../../../../Constant/api";
// import { slugs } from "../../../../Constant/slugs";
// import { AlertComponent } from "../../../AlertComponent";
// import { CustomButton } from "../../../CustomButtom";
// import ImageUpload from "../../../ImageUpload";
// import { login } from '../../../../redux/action';
// import { CustomButtonUnSelect } from "../../../CustomButtomUnselect";
// const studentRoles = ["subscriber", "student"]
// export default function FormComponent(props) {
//   const { FetchData } = props;
//   const history = useHistory()
//   const [ID, setID] = React.useState(FetchData.id)
//   const Login = useSelector((state) => state.LoginState);

//   const [author, setAuthor] = React.useState(FetchData.author)
//   const [Title, setTitle] = React.useState(FetchData.title.rendered)
//   const [UserRole, setUserRole] = React.useState(FetchData.meta.user_role)
//   const [ProfilePicture, setProfilePicture] = React.useState(FetchData.meta.user_profile_picture)
//   const [SubscriptionStart, setSubscriptionStart] = React.useState(FetchData.meta.user_subscription_start)
//   const [SubscriptionEnd, setSubscriptionEnd] = React.useState(FetchData.meta.user_subscription_end)
//   const [PhoneNumber, setPhoneNumber] = React.useState(FetchData.meta.user_phone_number)
//   const [VerificationStatus, setVerificationStatus] = React.useState(FetchData.meta.verification_status)
//   const [userID, setUserID] = React.useState(FetchData.meta.user_id)
//   const [userName, setUserName] = React.useState(FetchData.meta.user_name)
//   const [Password, setPassword] = React.useState(FetchData.meta.class_code)

//   const [ClassShift, setClassShift] = React.useState(FetchData.meta.class_shift)
//   const [ClassTime, setClassTime] = React.useState(FetchData.meta.class_time)
//   const [ExamTime, setExamTime] = React.useState(FetchData.meta.exam_time)
//   const [FeedbackTeacher, setFeedbackTeacher] = React.useState("")
//   const [FeedbackLink, setFeedbackLink] = React.useState("")
//   const [FeedbackTime, setFeedbackTime] = React.useState("")

//   const [userEmail, setUserEmail] = React.useState(FetchData.meta.user_email)
//   const [userVerificationHistory, setUserVerificationHistory] = React.useState(FetchData.meta.user_verification_history)
//   const [batchID, setBatchID] = React.useState(FetchData.meta.user_featured_exam_slug)
//   const [batchName, setBatchName] = React.useState(FetchData.meta.user_featured_exam_slug)
//   const [batchModal, setBatchModal] = React.useState(false)
//   const [batchList, setBatchList] = React.useState([])
//   const [ExamSelect, setExamSelect] = React.useState([])
//   const [serachName, setSearchName] = React.useState("")
//   const [ExamArray, setExamArray] = React.useState([])
//   const [ExamArray1, setExamArray1] = React.useState([])

//   const [userVerificationHistoryModal, setUserVerificationHistoryModal] = React.useState(false)
//   const [editKey, setEditKey] = React.useState(FetchData.meta.user_verification_history)
//   const [EditScreenShot, setEditScreenShot] = React.useState("")
//   const [EditStartDate, setEditStartDate] = React.useState("")
//   const [EditEndDate, setEditEndDate] = React.useState("")
//   const [EditAmountPaid, setEditAmountPaid] = React.useState("")
//   const [EditRemarks1, setEditRemarks1] = React.useState("")
//   const [EditRemarks2, setEditRemarks2] = React.useState("")
//   const [subscriptionTime, setSubscriptionTime] = React.useState("")
//   const [Datapassword, setDataPassword] = React.useState(true)
//   const [VerifyTime, setTime] = React.useState("")
//   const [UBTC, setUBTC] = React.useState(true)
//   const [Free, setFree] = React.useState("All")

//   // const [Name, setName] = React.useState(FetchData.title.rendered)
//   const dispatch = useDispatch()

//   const [reload, setReload] = React.useState(false)

//   const [AlertShow, setAlertShow] = React.useState(false)
//   const [Alertcomp, setAlertcomp] = React.useState("")
//   const [InstituteID, setInstituteID] = React.useState(FetchData.meta.batch_institute_id)
//   const [InstituteName, setInstituteName] = React.useState(FetchData.meta.batch_institute_name)
//   const [InstituteModal, setInstituteModal] = React.useState(false)
//   const [InstituteList, setInstituteList] = React.useState([])

//   const AfterUserCreate = (id) => {
//     const data = {
//       "title": Title,
//       "status": "publish",
//       "author": id,
//       "meta": {
//         "user_role": "teacher",
//         "user_profile_picture": ProfilePicture,
//         "user_phone_number": PhoneNumber,
//         "verification_status": VerificationStatus,
//         "user_id": `${id}`,
//         "user_name": userName,
//         "user_email": userEmail,
//         "user_verification_history": userVerificationHistory,
//         "batch_id": "",
//         "batch_name": "",
//         "user_featured_exam": batchName,
//         "user_featured_exam_slug": batchID,
//         "number_of_exams": "",
//         "class_code": Password,
//         "class_shift": ClassShift,
//         "class_time": ClassTime,
//         "exam_time": ExamTime,
//         "batch_institute_name": InstituteName,
//         "batch_institute_id": InstituteID,
//       }
//     }
//     if (ID === "")
//       AdsPOSTApi(data, slugs.db_slug_user_data)
//         .then((res) => {
//           setReload(false)
//           setID(res.data.id)
//           setAlertShow(true)
//           setAlertcomp("Book Added Successfully")
//           history.push(`${slugs.student_details}${res.data.slug}`)
//         }).catch(err => {
//           setReload(false)
//           console.log(err)
//           setAlertShow(true)
//           setAlertcomp(err.response.data.message)
//         })
//     else
//       AdsPUTApi(data, slugs.db_slug_user_data, ID)
//         .then((res) => {
//           setReload(false)
//           setAlertShow(true)
//           setAlertcomp(`${Title} edited Successfully`)
//         }).catch(err => {
//           console.log(err)
//           console.warn(err.response.data)
//           console.log("Response Status: hello11", err.response.status);
//           console.log("Response Headers:", err.response.headers);
//           setReload(false)
//           setAlertShow(true)
//           setAlertcomp(err.response.data.message)
//         })
//   }
//   const EditInfo = () => {
//     setReload(true)

//     if (ID == "") {
//       const dataWithPassword = {
//         "username": userName,
//         "email": userEmail,
//         "password": Password,
//         "roles": "teacher"
//       }
//       AdsPOSTApi(dataWithPassword, "users")
//         .then(res => {
//           AfterUserCreate(res.data.id)
//         })
//         .catch(e =>
//           console.log(e)
//         )
//     }
//     else {
//       if (Datapassword) {
//         const dataWithPassword = {
//           "password": Password,
//           "roles": "teacher"
//         }
//         if (Login.userID !== userID)
//           AdsPUTApi(dataWithPassword, `users`, `${userID}`)
//             .then(res => {
//               AfterUserCreate(userID)
//             })
//             .catch(e =>
//               console.log(e)
//             )
//         else {
//           setAlertShow(true)
//           setAlertcomp("You are not allowed to cahnge owns password")
//           setReload(false)
//         }
//       }
//       else {
//         const data = {
//           "roles": UserRole
//         }
//         AdsPUTApi(data, `users`, `${userID}`)
//           .then(res => {
//             AfterUserCreate(userID)
//           })
//           .catch(e =>
//             console.log(e)
//           )
//       }
//     }
//   }

//   const dateChangePressed = (verifyTime) => {
//     const date = new Date();

//     const monthToday = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
//     const yearToday = date.getUTCFullYear() < 9 ? `0${date.getUTCFullYear()}` : date.getUTCFullYear();

//     const monthnew = (date.getMonth() + 1 + verifyTime) % 12;
//     const month = monthnew < 9 ? `0${monthnew}` : `${monthnew}`
//     const day = date.getDate() < 9 ? `0${date.getDate()}` : `${date.getDate()}`
//     const year = date.getMonth() < (11 - verifyTime) ? `${date.getUTCFullYear()}` : `${date.getUTCFullYear() + 1}`
//     setEditStartDate(`${yearToday}-${monthToday}-${day}`)
//     setEditEndDate(`${year}-${month}-${day}`)
//   }

//   useEffect(() => {
//     BatchList()
//     FetchCourseList()
//     GetAllUsers()

//   }, [])
//   const BatchList = () => {
//     AdsGETApiAuth(
//       { per_page: 1000, _fields: "id,slug,title,meta.exam_type" },
//       slugs.db_slug_paid_exam
//     )
//       .then((res) => {
//         setExamArray1(res.data)
//         setExamArray(res.data.filter(a => a.meta.exam_type == "UBT"))
//         setExamSelect(res.data)
//       })
//       .catch(e => {
//         console.log(e)
//       })
//   }
//   const RandomVar = () => {
//     var chars = "0123456789";
//     var passwordLength = 3;
//     var password = "";
//     for (let l = 0; l <= passwordLength; l++) {
//       var randomNumber = Math.floor(Math.random() * chars.length);
//       password += chars.substring(randomNumber, randomNumber + 1);
//     }
//     setPassword(password)
//     // return password;
//   }
//   const FetchCourseList = () => {
//     AdsGETApiAuth(
//       { per_page: 300, _fields: "id,alug,title,meta.featured_image,meta.category_name" },
//       slugs.db_slug_batch
//     )
//       .then((res) => {
//         setCourseArray1(res.data)
//         setCourseArray(res.data)
//       })
//       .catch(e => {
//         console.log(e)
//       })
//   }


//   const EditNotice = () => {
//     setReload(true)
//     var firstText = `
// आजको लागि हाम्रो दैनिक परीक्षाको जानकारी निम्न रहेको छ।
    
// परीक्षा दिन मिल्ने समय - ${ExamTime} पछि ।
// Teacher ID नम्बर - ${userName}
// Todays Code - ${Password}
// `
//     var secondText = FeedbackTeacher !== "" ? `

// Feedback by: ${FeedbackTeacher}
// Feedback at: ${FeedbackTime}

// `: ""
//     var thirdText = `
// सम्पुर्णले तल दाँया भागमा रहेकाे  Start Test मा गएर  Teacher ID Number र Todays CODE राखि परिक्षा दिनु होला   ।
// `
//     var fourthText = FeedbackLink !== "" ? `
// यो परीक्षाको Feedback कक्षाको लागि तोकिएको समयमा तलको Open link मा Click गरेर जोडिनु होला ।
// `: ""
//     var fifthText = "BEST OF LUCK."

//     const data = {
//       "title": "कक्षागत दैनिक परिक्षाकाे जानकारी",
//       "status": "publish",
//       "meta": {
//         "featured_image": "",
//         "description": firstText + thirdText + fifthText + secondText + fourthText,
//         "notice_for": "",
//         "notice_for_list": JSON.stringify(VerifiedCourse),
//         "notice_for_all": `${Free}`,
//         "link_outside": `${FeedbackLink}`,
//         "classroom": "ClassRoom",
//         "teacher_id_number": `${userName}`,
//         "teacher_code": `${Password}`,
//       }
//     }

//     AdsPOSTApi(data, slugs.db_slug_notice)
//       .then((res) => {
//         setReload(false)
//         setID(res.data.id)
//         setAlertShow(true)
//         setAlertcomp("Notice Added Successfully")
//         // history.push(`${ slugs.notice_details } ${ res.data.slug } `)
//       }).catch(err => {
//         setReload(false)
//         console.log(err)
//         setAlertShow(true)
//         setAlertcomp(err.response.data.message)
//       })
//   }

//   const GetAllUsers = () => {
//     setReload(true)
//     setInstituteList([])
//     AdsGETApiAuth({
//       per_page: 100,
//       _fields: "id,title,slug,meta",
//       "filter[meta_query][1][key]": "user_role",
//       "filter[meta_query][1][value][0]": `creater`,
//       "filter[meta_query][1][compare]": "=",
//     }, slugs.db_slug_user_data)
//       .then((res) => {
//         setInstituteList(res.data)
//         console.log("done")
//       })
//       .catch(err => {
//         console.log(err)
//       }).finally(() => {
//         setReload(false)
//       })
//   }

//   const [AddNewCourse, setAddNewCourse] = React.useState(false)
//   const [serachCourseName, setSearchCourseName] = React.useState("")
//   const [CourseArray, setCourseArray] = React.useState([])
//   const [CourseArray1, setCourseArray1] = React.useState([])
//   const [VerifiedCourse, setVerifiedCourse] = React.useState([])
//   const [VerifiedCourseCache, setVerifiedCourseCache] = React.useState([])
//   const [CourseDeleteIndex, setCourseIndex] = React.useState("")
//   const [CourseDeleteModal, setCourseDeleteModal] = React.useState(false)


//   const MappingCourse = ({ item }) => {
//     const EnrollmentSearch = Object.values(VerifiedCourseCache).filter(a => a.course_id == item.id)
//     const [CheckON, setCheckON] = React.useState(EnrollmentSearch.length == 1 ? true : false)
//     const clickFunction = () => {
//       setCheckON(true)
//       if (VerifiedCourseCache.filter(a => a.course_id == item.id).length == 0) {
//         VerifiedCourseCache.push({
//           course_id: item.id,
//           name: item.title.rendered,
//           category: item.meta.category_name,
//           slug: item.slug
//         })
//       }
//     }
//     return (
//       <>
//         <td onClick={() => { clickFunction() }}>
//           {CheckON ?
//             <i className="fa fa-check-square-o" />
//             :
//             <i className="fa fa-square-o" />
//           }
//         </td>
//         <td>{item.id}</td>
//         <td>{item.title.rendered}</td>
//         <td>{item.meta.category_name}</td>
//         <td style={{ cursor: "pointer" }} onClick={() => { clickFunction() }}>Choose</td>
//       </>
//     )
//   }


//   return (
//     <div>{console.log(Login)}
//       <div className="row">
//         <div className="col-sm-2 col-1"></div>
//         <div className="col-sm-8 col-10 form-view mb-3">
//           <h4><center>Teacher Information </center></h4>
//           <table>
//             <tbody>
//               {ID !== "" &&
//                 <tr>
//                   <td className="col-3">Name </td>
//                   <td className="col-6">
//                     <div className="iinput-common">
//                       {userName}
//                     </div>
//                   </td>
//                 </tr>
//               }
//               <tr>
//                 <td className="col-3">Profile Picture</td>
//                 <td className="col-6">
//                   <ImageUpload
//                     ImageUrl={ProfilePicture}
//                     setImageUrl={setProfilePicture}
//                   />
//                 </td>
//               </tr>
//               {ID == "" &&
//                 <>
//                   <tr>
//                     <td className="col-3">Class Name</td>
//                     <td className="col-6">
//                       <input className="input-common"
//                         value={userName} placeholder="User Name"
//                         onChange={(event) => { setUserName(event.target.value) }}
//                       />
//                     </td>
//                   </tr>
//                   <tr>
//                     <td className="col-3">Teacher ID Number</td>
//                     <td className="col-6">
//                       <input className="input-common"
//                         value={userName} placeholder="User Name"
//                         onChange={(event) => { setUserName(event.target.value) }}
//                       />
//                     </td>
//                   </tr>
//                 </>
//               }

//               {/* <tr>
//                 <td className="col-3">User Email</td>
//                 <td className="col-6">
//                   {ID == "" ?
//                     <input className="input-common"
//                       value={userEmail} placeholder="User Email"
//                       onChange={(event) => { setUserEmail(event.target.value) }}
//                     />
//                     :
//                     <div className="input-common" >{userEmail}</div>
//                   }
//                 </td>
//               </tr> */}
//               <tr>
//                 <td className="col-3">Todays Code</td>
//                 <td className="col-6">
//                   {/* <input className="input-common"
//                     value={Password} placeholder="Class Code"
//                     onChange={(event) => { setPassword(event.target.value) }}
//                   /> */}
//                   <div className="input-common">
//                     <input className="input-common" style={{ width: "90%", padding: 0, border: "none" }}
//                       value={Password} placeholder="Token"
//                       onChange={(event) => { setPassword(event.target.value) }}
//                     />
//                     <span onClick={() => {
//                       RandomVar()
//                     }}>
//                       <img src="https://api.helloepskorea.com/wp-content/uploads/2024/01/images.jpeg"
//                         style={{ width: "20px", height: "20px" }}
//                       />
//                     </span>
//                   </div>

//                 </td>
//               </tr>
//               <tr>
//                 <td className="col-3">Class Shift</td>
//                 <td className="col-6">
//                   <input className="input-common"
//                     value={ClassShift} placeholder="Class Shift"
//                     onChange={(event) => { setClassShift(event.target.value) }}
//                   />

//                 </td>
//               </tr>
//               <tr>
//                 <td className="col-3">Class Time</td>
//                 <td className="col-6">
//                   <input className="input-common"
//                     value={ClassTime} placeholder="Class Time"
//                     onChange={(event) => { setClassTime(event.target.value) }}
//                   />

//                 </td>
//               </tr>
//               <tr>
//                 <td className="col-3">Exam Time </td>
//                 <td className="col-6">
//                   <input className="input-common"
//                     value={ExamTime} placeholder="Exam Time"
//                     onChange={(event) => { setExamTime(event.target.value) }}
//                   />
//                 </td>
//               </tr>
//               <tr>
//                 <td className="col-3">Select Exam </td>
//                 <td className="col-6">
//                   <div className="row">
//                     <div className="col-8">
//                       <div className="input-common"
//                         onClick={() => {
//                           setBatchModal(!batchModal)
//                         }}>
//                         {batchName}&nbsp;
//                       </div>
//                     </div>
//                     <div className="col-4"
//                       onClick={() => {
//                         setBatchID("")
//                         setBatchName("")
//                       }}
//                     >
//                       Clear
//                     </div>
//                   </div>
//                 </td>
//               </tr>
//               <>
//                 <tr>
//                   <td className="col-3">Entry By</td>
//                   <td className="col-6"
//                     onClick={() => {
//                       setInstituteModal(!InstituteModal)
//                     }}
//                   >
//                     <div className="input-common">
//                       {InstituteID}&nbsp;
//                     </div>
//                   </td>
//                 </tr>
//                 <tr>
//                   <td className="col-3">Entry Name</td>
//                   <td className="col-6"
//                     onClick={() => {
//                       setInstituteModal(!InstituteModal)
//                     }}
//                   >
//                     <div className="input-common">
//                       {InstituteName}&nbsp;
//                     </div>
//                     {InstituteModal &&
//                       <section className="modal">
//                         <div className="row">
//                           <div className="col-md-3 col-1"></div>
//                           <div className="col-md-6 col-10  shadow bg-white">
//                             <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
//                               <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
//                                 onClick={() => {
//                                   setInstituteID('')
//                                   setInstituteName("")
//                                   setInstituteModal(!InstituteModal)
//                                 }}
//                               >X</div>

//                               <div onClick={() => {
//                                 setInstituteID('')
//                                 setInstituteName("")
//                                 setInstituteModal(!InstituteModal)
//                               }}>
//                                 Clear
//                               </div>
//                               <table>
//                                 {InstituteList.map((item, index) => (
//                                   <tr key={index}
//                                     style={{ cursor: "pointer" }}
//                                     onClick={() => {
//                                       setInstituteID(`${item.meta.user_id}`)
//                                       setInstituteName(item.meta.user_name)
//                                       setInstituteModal(!InstituteModal)
//                                     }}
//                                   >
//                                     <td className="col-1">
//                                       {InstituteID !== item.id ?
//                                         <i className="fa fa-square-o" />
//                                         :
//                                         <i className="fa fa-check-square-o" />
//                                       }
//                                     </td>
//                                     <td className="col-2">
//                                       {item.id}
//                                     </td>
//                                     <td className="col-3">
//                                       {item.meta.user_name}
//                                     </td>
//                                     <td className="col-3">
//                                       {item.meta.user_email}
//                                     </td>
//                                     <td className="col-3">
//                                       {item.meta.phone_number}
//                                     </td>

//                                   </tr>
//                                 ))}
//                               </table>
//                             </div>
//                           </div>
//                         </div>
//                       </section>
//                     }
//                   </td>
//                 </tr>
//               </>
//             </tbody>
//           </table>
//           <div className="row center m-2">
//             <div className=" col-3" style={{ padding: "0 10px" }}>
//               <div className="sign-in-button-4"
//                 onClick={() => {
//                   EditInfo()
//                 }}
//               >
//                 Update Teacher
//               </div>
//             </div>
//           </div>

//         </div>
//       </div>
//       {batchModal &&
//         <section className="modal" >
//           <div className="row">
//             <div className="col-md-3 col-1"></div>
//             <div className="col-md-6 col-10  shadow bg-white">
//               <div style={{ maxHeight: "400px", margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
//                 <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
//                   onClick={() => {
//                     setBatchModal(!batchModal)
//                   }}
//                 >X</div>
//                 <div className="row" style={{ cursor: "pointer", border: "1px solid #999" }}>

//                   <div className="row" style={{ width: "100%", justifyContent: "space-evenly", cursor: "pointer", border: "1px solid #999" }} >
//                     <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
//                       value={serachName} placeholder="Name"
//                       onChange={(event) => {
//                         setSearchName(event.target.value)
//                         setExamArray(ExamArray1.filter(a => a.title.rendered.toLowerCase().includes(event.target.value.toLowerCase())))
//                       }}
//                     />
//                     <div className={UBTC == 0 ? "examselectStudent" : "examunselectStudent"}
//                       onClick={() => {
//                         setUBTC(0)
//                         setExamArray(ExamArray1.filter(a => a.meta.exam_type == "UBT" || a.meta.exam_type == "Agriculture" || a.meta.exam_type == "Ship Building" || a.meta.exam_type == "Manufacturing"))
//                       }}
//                     >
//                       UBT
//                     </div>
//                     <div className={UBTC == 1 ? "examselectStudent" : "examunselectStudent"} onClick={() => {
//                       setUBTC(1)
//                       setExamArray(ExamArray1.filter(a => a.meta.exam_type == "Chapter"))
//                     }} >
//                       ChapterWise
//                     </div>
//                   </div>
//                 </div>
//                 <table>
//                   <tbody>
//                     {ExamArray.map((item, index) => (
//                       <tr className="row bg-white shadow" key={index}
//                         style={{ overflowX: "hidden", cursor: "pointer", border: "1px solid #999" }}
//                         onClick={() => {
//                           setBatchID(`${item.slug} `)
//                           setBatchName(item.title.rendered)
//                           setBatchModal(!batchModal)
//                         }}
//                       >
//                         <td className="col-1">
//                           {batchID !== item.id ?
//                             <i className="fa fa-square-o" />
//                             :
//                             <i className="fa fa-check-square-o" />
//                           }
//                         </td>
//                         <td className="col-2" >
//                           {item.id}
//                         </td>
//                         <td className="col-5">
//                           {item.title.rendered}
//                         </td>
//                         <td className="col-3">
//                           {item.meta.exam_type}
//                         </td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               </div>
//             </div>
//           </div>
//         </section>
//       }


//       <div className="row">
//         <div className="col-sm-2 col-1"></div>
//         <div className="col-sm-8 col-10 form-view mb-3">
//           <h4><center>Notice </center></h4>
//           <table>
//             <tbody>
//               <tr>
//                 <td className="col-3">Feedback Teacher</td>
//                 <td className="col-6">
//                   <input className="input-common"
//                     value={FeedbackTeacher} placeholder="FeedBack Teacher"
//                     onChange={(event) => { setFeedbackTeacher(event.target.value) }}
//                   />
//                 </td>
//               </tr>
//               <tr>
//                 <td className="col-3">Feedback Time</td>
//                 <td className="col-6">
//                   <input className="input-common"
//                     value={FeedbackTime} placeholder="FeedBack Time"
//                     onChange={(event) => { setFeedbackTime(event.target.value) }}
//                   />
//                 </td>
//               </tr>
//               <tr>
//                 <td className="col-3">Feedback Link</td>
//                 <td className="col-6">
//                   <input className="input-common"
//                     value={FeedbackLink} placeholder="FeedBack Link"
//                     onChange={(event) => { setFeedbackLink(event.target.value) }}
//                   />
//                 </td>
//               </tr>
//               <tr>
//                 <td className="col-3">Notice For All</td>
//                 <td className="col-6">
//                   <CustomButtonUnSelect setData={setFree} Data={Free} DataValue={"All"} />
//                 </td>
//               </tr>
//             </tbody>
//           </table>
//           <table>
//             <tbody>
//               <tr>
//                 <td>Course ID</td>
//                 <td>Course Name</td>
//                 <td>Category Name</td>
//               </tr>
//               {VerifiedCourse.map((item, index) => (
//                 <tr key={index}>
//                   <td>{item.course_id}</td>
//                   <td>{item.name}</td>
//                   <td>{item.category}</td>
//                   <td style={{ cursor: "pointer" }} onClick={() => {
//                     setCourseIndex(index)
//                     setCourseDeleteModal(true)
//                   }}>Delete</td>
//                 </tr>
//               ))}
//               <tr>
//                 <td style={{ cursor: "pointer" }} onClick={() => {
//                   setAddNewCourse(true)
//                 }}>Add New</td>
//               </tr>
//             </tbody>
//           </table>
//           <div className="row center m-2">
//             <div className=" col-3" style={{ padding: "0 10px" }}>
//               <div className="sign-in-button-4"
//                 onClick={() => {
//                   EditNotice()
//                 }}
//               >
//                 Send Notice
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {CourseDeleteModal &&
//         <section className="modal" >
//           <div className="row">
//             <div className="col-md-3 col-1"></div>
//             <div className="col-md-6 col-10 shadow bg-white">
//               <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
//                 <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
//                   onClick={() => {
//                     setCourseDeleteModal(false)
//                   }}
//                 >X</div>
//                 Do you really want to delete Course for the user?
//                 <table>
//                   <tbody>
//                     <tr>
//                       <td>Course Name : </td>
//                       <td>{VerifiedCourse[CourseDeleteIndex].name}</td>
//                     </tr>
//                     <tr>
//                       <td>Course ID : </td>
//                       <td>{VerifiedCourse[CourseDeleteIndex].course_id}</td>
//                     </tr>
//                   </tbody>
//                 </table>
//                 <div className="row" >
//                   <div className="col-1 center " />
//                   <div className="col-5 center " >
//                     <div className="sign-in-button-4" style={{ width: "80%" }}
//                       onClick={() => {
//                         setCourseDeleteModal(false)
//                       }}
//                     >
//                       Cancel
//                     </div>
//                   </div>
//                   <div className="col-5 center">
//                     <div className="sign-in-button-4" style={{ width: "80%" }}
//                       onClick={() => {
//                         VerifiedCourse.splice(CourseDeleteIndex, 1)
//                         // BatchExamID.splice(BatchExamDeleteIndex, 1)
//                         setCourseDeleteModal(false)
//                       }}
//                     >
//                       OK
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//       }

//       {AddNewCourse &&
//         <section className="modal" >
//           <div className="row">
//             <div className="col-md-3 col-1"></div>
//             <div className="col-md-6 col-10 shadow bg-white">
//               <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
//                 <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
//                   onClick={() => {
//                     setAddNewCourse(false)
//                   }}
//                 >X</div>
//                 <div className="row" style={{ width: "100%", justifyContent: "space-evenly", cursor: "pointer", border: "1px solid #999" }} >
//                   <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
//                     value={serachCourseName} placeholder="Name"
//                     onChange={(event) => {
//                       setSearchCourseName(event.target.value)
//                       setCourseArray(CourseArray1.filter(a => a.title.rendered.toLowerCase().includes(event.target.value.toLowerCase())))
//                     }}
//                   />
//                 </div>

//                 <div style={{ height: "400px", overflowY: "scroll" }}>
//                   <table>
//                     <tbody>
//                       <tr>
//                         <td></td>
//                         <td>Course ID</td>
//                         <td>Course Name</td>
//                         <td>Batch Category</td>
//                         <td>Choose</td>
//                       </tr>
//                       {CourseArray.map((item, index) => (
//                         <tr key={index}>
//                           <MappingCourse item={item} />
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//                 <div style={{ padding: "10px" }}>
//                   <div className="row">
//                     <div className="col-1" />
//                     <div className="col-5 center " >
//                       <div className="sign-in-button-4" style={{ width: "80%" }}
//                         onClick={() => {
//                           setVerifiedCourseCache(VerifiedCourse)
//                           // setBatchExamIDCache(BatchExamID)
//                           setAddNewCourse(false)
//                         }}
//                       >
//                         Cancel
//                       </div>
//                     </div>
//                     <div className="col-5 center">
//                       <div className="sign-in-button-4" style={{ width: "80%" }}
//                         onClick={() => {
//                           setVerifiedCourse(VerifiedCourseCache)
//                           // setBatchExamID(BatchExamIDCache)
//                           setAddNewCourse(false)
//                         }}
//                       >
//                         Done
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>


//             </div>
//           </div>
//         </section>
//       }


//       {/* <div className="row">
//         <div className="col-sm-2 col-1"></div>
//         <div className="col-sm-8 col-10 form-view mb-3">
//           <h4><center>Verification History</center></h4>
//           <table>
//             <tbody>
//               <tr>
//                 <td >Screenshot</td>
//                 <td >Start Date</td>
//                 <td >End Date</td>
//                 <td >Amount Paid</td>
//                 <td ></td>
//               </tr>
//               {Object.values(userVerificationHistory).map((item, index) => (
//                 <tr key={index}>
//                   <td >
//                     {item.verify_screenshot !== "" &&
//                       <a href={item.verify_screenshot} target="_blank">
//                         <img src={item.verify_screenshot} style={{ width: "20px", height: "20px" }} />
//                       </a>
//                     }
//                   </td>
//                   <td >{item.verify_start_date}</td>
//                   <td >{item.verify_end_date}</td>
//                   <td >{item.amount_paid}</td>
//                   <td
//                     onClick={() => {
//                       setUserVerificationHistoryModal(true)
//                       setEditScreenShot(item.verify_screenshot)
//                       setEditStartDate(item.verify_start_date)
//                       setEditEndDate(item.verify_end_date)
//                       setEditAmountPaid(item.amount_paid)
//                       setEditRemarks1(item.verification_remarks_1)
//                       setEditRemarks2(item.verification_remarks_2)
//                       setEditKey(Object.keys(userVerificationHistory)[index])
//                     }}
//                   >Edit</td>
//                 </tr>
//               ))}
//               <tr>
//                 <td colSpan={4}
//                   onClick={() => {
//                     setUserVerificationHistoryModal(true)
//                     setEditScreenShot("")
//                     setEditStartDate("")
//                     setEditEndDate("")
//                     setEditAmountPaid("")
//                     setEditRemarks1("")
//                     setEditRemarks2("")
//                     setEditKey(`item - ${ Object.keys(userVerificationHistory).length } `)
//                   }}
//                 >
//                   <center><div style={{ width: "100px" }} className="sign-in-button-4"> New</div></center>
//                 </td>
//               </tr>
//             </tbody>
//           </table>
//         </div >
//       </div >
//       */}

//       {userVerificationHistoryModal &&
//         <section className="modal" >
//           <div className="row">
//             <div className="col-md-3 col-1"></div>
//             <div className="col-md-6 col-10  shadow bg-white">
//               <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
//                 <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
//                   onClick={() => {
//                     setUserVerificationHistoryModal(false)
//                     setEditKey("")
//                   }}
//                 >X</div>
//                 <table>
//                   <tr>
//                     <td className="col-4"><b>SN : </b></td>
//                     <td className="col-7">
//                       {Number(editKey.replace("item-", "")) + 1}
//                     </td>
//                   </tr>
//                   <tr>
//                     <td className="col-4"><b>ScreenShot : </b></td>
//                     <td className="col-7">
//                       <ImageUpload
//                         ImageUrl={EditScreenShot}
//                         setImageUrl={setEditScreenShot}
//                       />
//                       {EditScreenShot !== "" &&
//                         <img src={EditScreenShot} style={{ height: "200px", width: "200px", objectFit: "contain" }} />
//                       }
//                     </td>
//                   </tr>
//                   <tr>
//                     <td className="col-4"><b>Subscription : </b></td>
//                     <td>
//                       <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
//                         onClick={() => {
//                           setSubscriptionTime("3 months")
//                           dateChangePressed(3)
//                         }}
//                       >
//                         <div className={subscriptionTime == "3 months" ? "active-option" : "passive-option"}>
//                           3 months
//                         </div>
//                       </button>
//                       <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
//                         onClick={() => {
//                           setSubscriptionTime("6 months")
//                           dateChangePressed(6)
//                         }}
//                       >
//                         <div className={subscriptionTime == "6 months" ? "active-option" : "passive-option"}>
//                           6 months
//                         </div>
//                       </button>
//                       <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
//                         onClick={() => {
//                           setSubscriptionTime("1 year")
//                           dateChangePressed(12)
//                         }}
//                       >
//                         <div className={subscriptionTime == "1 year" ? "active-option" : "passive-option"}>
//                           12 months
//                         </div>
//                       </button>
//                       <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
//                         onClick={() => {
//                           setSubscriptionTime("Custom")
//                         }}
//                       >
//                         <div className={subscriptionTime == "Custom" ? "active-option" : "passive-option"}>
//                           Custom
//                         </div>
//                       </button>
//                     </td>
//                   </tr>
//                   <tr>
//                     <td className="col-4"><b>Start Date : </b></td>
//                     <td className="col-7">
//                       {subscriptionTime == "Custom" ?
//                         <input type="date"
//                           className="p-1 px-2 col-12"
//                           value={EditStartDate}
//                           onChange={(event) => {
//                             setEditStartDate(event.target.value)
//                           }}
//                           placeholder="Start Date"
//                         />
//                         :
//                         <div>
//                           {EditStartDate}
//                         </div>
//                       }
//                     </td>
//                   </tr>
//                   <tr>
//                     <td className="col-4"><b>End Date : </b></td>
//                     <td className="col-7">
//                       {subscriptionTime == "Custom" ?
//                         <input type="date"
//                           className="p-1 px-2 col-12"
//                           value={EditEndDate}
//                           onChange={(event) => { setEditEndDate(event.target.value) }}
//                           placeholder="End Date"
//                         />
//                         :
//                         <div>
//                           {EditEndDate}
//                         </div>
//                       }
//                     </td>
//                   </tr>
//                   <tr>
//                     <td className="col-4"><b>Paid Amount : </b></td>
//                     <td className="col-7">
//                       <input
//                         className="p-1 px-2 col-12"
//                         value={EditAmountPaid}
//                         onChange={(event) => { setEditAmountPaid(event.target.value) }}
//                         placeholder="Amount Paid"
//                       />
//                     </td>
//                   </tr>
//                   <tr>
//                     <td className="col-4"><b>Remarks : </b></td>
//                     <td className="col-7">
//                       <input
//                         className="p-1 px-2 col-12"
//                         value={EditRemarks1}
//                         onChange={(event) => { setEditRemarks1(event.target.value) }}
//                         placeholder="Remarks"
//                       />
//                     </td>
//                   </tr>
//                   <tr>
//                     <td colSpan={2}>
//                       <center>
//                         <div style={{ width: "30%" }} className="sign-in-button-1"
//                           onClick={() => {
//                             setUserVerificationHistory({
//                               ...userVerificationHistory,
//                               [editKey]: {
//                                 'verify_screenshot': EditScreenShot,
//                                 'verify_start_date': EditStartDate,
//                                 'verify_end_date': EditEndDate,
//                                 'amount_paid': EditAmountPaid,
//                                 'verification_remarks_1': EditRemarks1,
//                                 'verification_remarks_2': EditRemarks2,
//                               }
//                             }
//                             )
//                             setUserVerificationHistoryModal(false)
//                           }}
//                         >Done</div>
//                       </center>
//                     </td>
//                   </tr>
//                 </table>
//               </div>
//             </div>
//           </div>
//         </section>
//       }


//       {
//         reload &&
//         <div className="modal" style={{ padding: "50vw" }}>
//           <div className="dot-pulse"></div>
//         </div>
//       }
//       {
//         AlertShow &&
//         <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
//       }
//     </div >
//   )

// }


import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi, AdsPUTApiToken } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import ImageUpload from "../../../ImageUpload";
import { login } from '../../../../redux/action';
import { CustomButtonUnSelect } from "../../../CustomButtomUnselect";
const studentRoles = ["subscriber", "student"]
export default function FormComponent(props) {
  const { FetchData } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)
  const Login = useSelector((state) => state.LoginState);

  const [author, setAuthor] = React.useState(FetchData.author)
  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [UserRole, setUserRole] = React.useState(FetchData.meta.user_role)
  const [ProfilePicture, setProfilePicture] = React.useState(FetchData.meta.user_profile_picture)
  const [SubscriptionStart, setSubscriptionStart] = React.useState(FetchData.meta.user_subscription_start)
  const [SubscriptionEnd, setSubscriptionEnd] = React.useState(FetchData.meta.user_subscription_end)
  const [PhoneNumber, setPhoneNumber] = React.useState(FetchData.meta.user_phone_number)
  const [VerificationStatus, setVerificationStatus] = React.useState(FetchData.meta.verification_status)
  const [userID, setUserID] = React.useState(FetchData.meta.user_id)
  const [userName, setUserName] = React.useState(FetchData.meta.user_name)
  const [Password, setPassword] = React.useState(FetchData.meta.class_code)

  const [ClassShift, setClassShift] = React.useState(FetchData.meta.class_shift)
  const [ClassTime, setClassTime] = React.useState(FetchData.meta.class_time)
  const [ExamTime, setExamTime] = React.useState(FetchData.meta.exam_time)
  const [FeedbackTeacher, setFeedbackTeacher] = React.useState(FetchData.meta.feedback_teacher)
  const [FeedbackLink, setFeedbackLink] = React.useState(FetchData.meta.feedback_link)
  const [FeedbackTime, setFeedbackTime] = React.useState(FetchData.meta.feedback_time)

  const [userEmail, setUserEmail] = React.useState(FetchData.meta.user_email)
  const [userVerificationHistory, setUserVerificationHistory] = React.useState(FetchData.meta.user_verification_history)
  const [batchID, setBatchID] = React.useState(FetchData.meta.user_featured_exam_slug)
  const [batchName, setBatchName] = React.useState(FetchData.meta.user_featured_exam_slug)
  const [batchModal, setBatchModal] = React.useState(false)
  const [batchList, setBatchList] = React.useState([])
  const [ExamSelect, setExamSelect] = React.useState([])
  const [serachName, setSearchName] = React.useState("")
  const [ExamArray, setExamArray] = React.useState([])
  const [ExamArray1, setExamArray1] = React.useState([])

  const [userVerificationHistoryModal, setUserVerificationHistoryModal] = React.useState(false)
  const [editKey, setEditKey] = React.useState(FetchData.meta.user_verification_history)
  const [EditScreenShot, setEditScreenShot] = React.useState("")
  const [EditStartDate, setEditStartDate] = React.useState("")
  const [EditEndDate, setEditEndDate] = React.useState("")
  const [EditAmountPaid, setEditAmountPaid] = React.useState("")
  const [EditRemarks1, setEditRemarks1] = React.useState("")
  const [EditRemarks2, setEditRemarks2] = React.useState("")
  const [subscriptionTime, setSubscriptionTime] = React.useState("")
  const [Datapassword, setDataPassword] = React.useState(true)
  const [VerifyTime, setTime] = React.useState("")
  const [UBTC, setUBTC] = React.useState(true)
  const [Free, setFree] = React.useState("All")

  // const [Name, setName] = React.useState(FetchData.title.rendered)
  const dispatch = useDispatch()

  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")
  const [InstituteID, setInstituteID] = React.useState(FetchData.meta.batch_institute_id)
  const [InstituteName, setInstituteName] = React.useState(FetchData.meta.batch_institute_name)
  const [InstituteModal, setInstituteModal] = React.useState(false)
  const [InstituteList, setInstituteList] = React.useState([])

  const [AddNewCourse, setAddNewCourse] = React.useState(false)
  const [serachCourseName, setSearchCourseName] = React.useState("")
  const [CourseArray, setCourseArray] = React.useState([])
  const [CourseArray1, setCourseArray1] = React.useState([])
  const [VerifiedCourse, setVerifiedCourse] = React.useState([])
  const [VerifiedCourseCache, setVerifiedCourseCache] = React.useState([])
  const [CourseDeleteIndex, setCourseIndex] = React.useState("")
  const [CourseDeleteModal, setCourseDeleteModal] = React.useState(false)
  const [CategoryArray, setCategoryArray] = React.useState([])
  const [choose, setChoose] = React.useState(0)

  const AfterUserCreate = (id) => {
    const data = {
      "title": Title,
      "status": "publish",
      "author": id,
      "meta": {
        "user_role": "teacher",
        "user_profile_picture": ProfilePicture,
        "user_phone_number": PhoneNumber,
        "verification_status": VerificationStatus,
        "user_id": `${id}`,
        "user_name": userName,
        "user_email": `${userName}@occoed.com`,
        "user_verification_history": userVerificationHistory,
        "batch_id": "",
        "batch_name": "",
        "user_featured_exam": batchName,
        "user_featured_exam_slug": batchID,
        "number_of_exams": "",
        "class_code": Password,
        "class_shift": ClassShift,
        "class_time": ClassTime,
        "exam_time": ExamTime,
        "batch_institute_name": InstituteName,
        "batch_institute_id": InstituteID,
        "feedback_teacher": FeedbackTeacher,
        "feedback_time": FeedbackTime,
        "feedback_link": FeedbackLink,
        "feedback_courses": JSON.stringify(VerifiedCourse)
      }
    }
    if (ID === "")
      AdsPOSTApi(data, slugs.db_slug_user_data)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Book Added Successfully")
          history.push(`${slugs.teacher_details}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slugs.db_slug_user_data, ID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }
  const EditInfo = () => {
    setReload(true)

    if (ID == "") {
      const dataWithPassword = {
        "username": userName,
        "email": `${userName}@occoed.com`,
        "password": Password,
        "roles": "teacher"
      }
      AdsPOSTApi(dataWithPassword, "users")
        .then(res => {
          AfterUserCreate(res.data.id)
        })
        .catch(e =>
          console.log(e)
        )
    }
    else {
      if (Datapassword) {
        const dataWithPassword = {
          "password": Password,
          "roles": "teacher"
        }
        if (Login.userID !== userID)
          AdsPUTApi(dataWithPassword, `users`, `${userID}`)
            .then(res => {
              AfterUserCreate(userID)
            })
            .catch(e =>
              console.log(e)
            )
        else {
          setAlertShow(true)
          setAlertcomp("You are not allowed to cahnge owns password")
          setReload(false)
        }
      }
      else {
        const data = {
          "roles": UserRole
        }
        AdsPUTApi(data, `users`, `${userID}`)
          .then(res => {
            AfterUserCreate(userID)
          })
          .catch(e =>
            console.log(e)
          )
      }
    }
  }

  const dateChangePressed = (verifyTime) => {
    const date = new Date();

    const monthToday = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const yearToday = date.getUTCFullYear() < 9 ? `0${date.getUTCFullYear()}` : date.getUTCFullYear();

    const monthnew = (date.getMonth() + 1 + verifyTime) % 12;
    const month = monthnew < 9 ? `0${monthnew}` : `${monthnew}`
    const day = date.getDate() < 9 ? `0${date.getDate()}` : `${date.getDate()}`
    const year = date.getMonth() < (11 - verifyTime) ? `${date.getUTCFullYear()}` : `${date.getUTCFullYear() + 1}`
    setEditStartDate(`${yearToday}-${monthToday}-${day}`)
    setEditEndDate(`${year}-${month}-${day}`)
  }

  useEffect(() => {
    BatchList()
    FetchCourseList()
    GetAllUsers()
    FetchCategoryList()

  }, [])
  const BatchList = () => {
    AdsGETApiAuth(
      { per_page: 1000, _fields: "id,slug,title,meta.exam_type,meta.category_id" },
      slugs.db_slug_paid_exam
    )
      .then((res) => {
        setExamArray1(res.data)
        setExamArray(res.data.filter(a => a.meta.exam_type == "UBT"))
        setExamSelect(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }
  const FetchCategoryList = () => {
    AdsGETApiAuth(
      { per_page: 300, _fields: "id,alug,title,meta" },
      slugs.db_slug_category
    )
      .then((res) => {
        setCategoryArray(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }
  const RandomVar = () => {
    var chars = "0123456789";
    var passwordLength = 3;
    var password = "";
    for (let l = 0; l <= passwordLength; l++) {
      var randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }
    setPassword(password)
    // return password;
  }
  const FetchCourseList = () => {
    AdsGETApiAuth(
      { per_page: 300, _fields: "id,alug,title,meta.featured_image,meta.category_name" },
      slugs.db_slug_batch
    )
      .then((res) => {
        setCourseArray1(res.data)
        setCourseArray(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }


  const EditNotice = () => {
    setReload(true)
    var firstText = `
आजको लागि हाम्रो दैनिक परीक्षाको जानकारी निम्न रहेको छ।
    
Group - ${Title} ।
परीक्षा दिन मिल्ने समय - ${ExamTime} पछि ।
Teacher ID नम्बर - ${userName}
Todays Code - ${Password}
`
    var secondText = FeedbackTeacher !== "" ? `

Feedback by: ${FeedbackTeacher}
Feedback at: ${FeedbackTime}

`: ""
    var thirdText = `
सम्पुर्णले तल दाँया भागमा रहेकाे  Start Test मा गएर  Teacher ID Number र Todays CODE राखि परिक्षा दिनु होला   ।
`
    var fourthText = FeedbackLink !== "" ? `
यो परीक्षाको Feedback कक्षाको लागि तोकिएको समयमा तलको Open link मा Click गरेर जोडिनु होला ।
`: ""
    var fifthText = "BEST OF LUCK."

    const data = {
      "title": "कक्षागत दैनिक परिक्षाकाे जानकारी",
      "status": "publish",
      "meta": {
        "featured_image": "",
        "description": firstText + thirdText + fifthText + secondText + fourthText,
        "notice_for": "",
        "notice_for_list": JSON.stringify(VerifiedCourse),
        "notice_for_all": `${Free}`,
        "link_outside": `${FeedbackLink}`,
        "classroom": "ClassRoom",
        "teacher_id_number": `${userName}`,
        "teacher_code": `${Password}`,
      }
    }

    AdsPOSTApi(data, slugs.db_slug_notice)
      .then((res) => {
        setReload(false)
        setID(res.data.id)
        setAlertShow(true)
        setAlertcomp("Notice Added Successfully")
        // history.push(`${ slugs.notice_details } ${ res.data.slug } `)
      }).catch(err => {
        setReload(false)
        console.log(err)
        setAlertShow(true)
        setAlertcomp(err.response.data.message)
      })
  }

  const GetAllUsers = () => {
    setReload(true)
    setInstituteList([])
    AdsGETApiAuth({
      per_page: 100,
      _fields: "id,title,slug,meta",
      "filter[meta_query][1][key]": "user_role",
      "filter[meta_query][1][value][0]": `creater`,
      "filter[meta_query][1][compare]": "=",
    }, slugs.db_slug_user_data)
      .then((res) => {
        setInstituteList(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }


  const MappingCourse = ({ item }) => {
    const EnrollmentSearch = Object.values(VerifiedCourseCache).filter(a => a.course_id == item.id)
    const [CheckON, setCheckON] = React.useState(EnrollmentSearch.length == 1 ? true : false)
    const clickFunction = () => {
      setCheckON(true)
      if (VerifiedCourseCache.filter(a => a.course_id == item.id).length == 0) {
        VerifiedCourseCache.push({
          course_id: item.id,
          name: item.title.rendered,
          category: item.meta.category_name,
          slug: item.slug
        })
      }
    }
    return (
      <>
        <td onClick={() => { clickFunction() }}>
          {CheckON ?
            <i className="fa fa-check-square-o" />
            :
            <i className="fa fa-square-o" />
          }
        </td>
        <td>{item.id}</td>
        <td>{item.title.rendered}</td>
        <td>{item.meta.category_name}</td>
        <td style={{ cursor: "pointer" }} onClick={() => { clickFunction() }}>Choose</td>
      </>
    )
  }


  return (
    <div>{console.log(Login)}
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Teacher Information </center></h4>
          <table>
            <tbody>
              {ID !== "" &&
                <tr>
                  <td className="col-3">Name </td>
                  <td className="col-6">
                    <div className="iinput-common">
                      {userName}
                    </div>
                  </td>
                </tr>
              }
              <tr>
                <td className="col-3">Profile Picture</td>
                <td className="col-6">
                  <ImageUpload
                    ImageUrl={ProfilePicture}
                    setImageUrl={setProfilePicture}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Class Name</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Title} placeholder="Class Name"
                    onChange={(event) => { setTitle(event.target.value) }}
                  />
                </td>
              </tr>
              {ID == "" &&
                <>

                  <tr>
                    <td className="col-3">Teacher ID Number</td>
                    <td className="col-6">
                      <input className="input-common"
                        value={userName} placeholder="User Name"
                        onChange={(event) => { setUserName(event.target.value) }}
                      />
                    </td>
                  </tr>
                </>
              }

              {/* <tr>
                <td className="col-3">User Email</td>
                <td className="col-6">
                  {ID == "" ?
                    <input className="input-common"
                      value={userEmail} placeholder="User Email"
                      onChange={(event) => { setUserEmail(event.target.value) }}
                    />
                    :
                    <div className="input-common" >{userEmail}</div>
                  }
                </td>
              </tr> */}
              <tr>
                <td className="col-3">Todays Code</td>
                <td className="col-6">
                  {/* <input className="input-common"
                    value={Password} placeholder="Class Code"
                    onChange={(event) => { setPassword(event.target.value) }}
                  /> */}
                  <div className="input-common">
                    <input className="input-common" style={{ width: "90%", padding: 0, border: "none" }}
                      value={Password} placeholder="Token"
                      onChange={(event) => { setPassword(event.target.value) }}
                    />
                    <span onClick={() => {
                      RandomVar()
                    }}>
                      <img src="https://api.helloepskorea.com/wp-content/uploads/2024/01/images.jpeg"
                        style={{ width: "20px", height: "20px" }}
                      />
                    </span>
                  </div>

                </td>
              </tr>
              <tr>
                <td className="col-3">Class Shift</td>
                <td className="col-6">
                  <input className="input-common"
                    value={ClassShift} placeholder="Class Shift"
                    onChange={(event) => { setClassShift(event.target.value) }}
                  />

                </td>
              </tr>
              <tr>
                <td className="col-3">Class Time</td>
                <td className="col-6">
                  <input className="input-common"
                    value={ClassTime} placeholder="Class Time"
                    onChange={(event) => { setClassTime(event.target.value) }}
                  />

                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Time </td>
                <td className="col-6">
                  <input className="input-common"
                    value={ExamTime} placeholder="Exam Time"
                    onChange={(event) => { setExamTime(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Select Exam </td>
                <td className="col-6">
                  <div className="row">
                    <div className="col-8">
                      <div className="input-common"
                        onClick={() => {
                          setBatchModal(!batchModal)
                        }}>
                        {batchName}&nbsp;
                      </div>
                    </div>
                    <div className="col-4"
                      onClick={() => {
                        setBatchID("")
                        setBatchName("")
                      }}
                    >
                      Clear
                    </div>
                  </div>
                </td>
              </tr>
              <>
                <tr>
                  <td className="col-3">Entry By</td>
                  <td className="col-6"
                    onClick={() => {
                      setInstituteModal(!InstituteModal)
                    }}
                  >
                    <div className="input-common">
                      {InstituteID}&nbsp;
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="col-3">Entry Name</td>
                  <td className="col-6"
                    onClick={() => {
                      setInstituteModal(!InstituteModal)
                    }}
                  >
                    <div className="input-common">
                      {InstituteName}&nbsp;
                    </div>
                    {InstituteModal &&
                      <section className="modal">
                        <div className="row">
                          <div className="col-md-3 col-1"></div>
                          <div className="col-md-6 col-10  shadow bg-white">
                            <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                              <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                                onClick={() => {
                                  setInstituteID('')
                                  setInstituteName("")
                                  setInstituteModal(!InstituteModal)
                                }}
                              >X</div>

                              <div onClick={() => {
                                setInstituteID('')
                                setInstituteName("")
                                setInstituteModal(!InstituteModal)
                              }}>
                                Clear
                              </div>
                              <table>
                                {InstituteList.map((item, index) => (
                                  <tr key={index}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setInstituteID(`${item.meta.user_id}`)
                                      setInstituteName(item.meta.user_name)
                                      setInstituteModal(!InstituteModal)
                                    }}
                                  >
                                    <td className="col-1">
                                      {InstituteID !== item.id ?
                                        <i className="fa fa-square-o" />
                                        :
                                        <i className="fa fa-check-square-o" />
                                      }
                                    </td>
                                    <td className="col-2">
                                      {item.id}
                                    </td>
                                    <td className="col-3">
                                      {item.meta.user_name}
                                    </td>
                                    <td className="col-3">
                                      {item.meta.user_email}
                                    </td>
                                    <td className="col-3">
                                      {item.meta.phone_number}
                                    </td>

                                  </tr>
                                ))}
                              </table>
                            </div>
                          </div>
                        </div>
                      </section>
                    }
                  </td>
                </tr>
              </>
            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo()
                }}
              >
                Update Teacher
              </div>
            </div>
          </div>

        </div>
      </div>
      {batchModal &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ maxHeight: "400px", margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setBatchModal(!batchModal)
                    setChoose(0)
                  }}
                >X</div>
                <div className="row" style={{ cursor: "pointer", border: "1px solid #999" }}>

                  <div className="row" style={{ width: "100%", justifyContent: "space-evenly", cursor: "pointer", border: "1px solid #999" }} >
                    <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
                      value={serachName} placeholder="Name"
                      onChange={(event) => {
                        setSearchName(event.target.value)
                        setExamArray(ExamArray1.filter(a => a.title.rendered.toLowerCase().includes(event.target.value.toLowerCase())))
                      }}
                    />
                    {/* <div className={UBTC == 0 ? "examselectStudent" : "examunselectStudent"}
                      onClick={() => {
                        setUBTC(0)
                        setExamArray(ExamArray1.filter(a => a.meta.exam_type == "UBT" || a.meta.exam_type == "Agriculture" || a.meta.exam_type == "Ship Building" || a.meta.exam_type == "Manufacturing"))
                      }}
                    >
                      UBT
                    </div>
                    <div className={UBTC == 1 ? "examselectStudent" : "examunselectStudent"} onClick={() => {
                      setUBTC(1)
                      setExamArray(ExamArray1.filter(a => a.meta.exam_type == "Chapter"))
                    }} >
                      ChapterWise
                    </div> */}
                  </div>
                </div>
                {choose == 0 ?
                  <table>
                    <tbody>
                      <tr>
                        <td>Category ID</td>
                        <td>Category Name</td>
                        <td>Choose</td>
                      </tr>
                      {CategoryArray.map((item, index) => (
                        <tr key={index} style={{ cursor: "pointer" }}
                          onClick={() => {
                            setChoose(1)
                            setExamArray(ExamArray1.filter(a => a.meta.category_id == item.id))
                          }}
                        >
                          <td> {item.id}</td>
                          <td> {item.title.rendered}</td>
                          <td> Choose</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  :
                  <table>
                    <tbody>
                      {ExamArray.map((item, index) => (
                        <tr className="row bg-white shadow" key={index}
                          style={{ overflowX: "hidden", cursor: "pointer", border: "1px solid #999" }}
                          onClick={() => {
                            setBatchID(`${item.slug} `)
                            setBatchName(item.title.rendered)
                            setBatchModal(!batchModal)
                          }}
                        >
                          <td className="col-1">
                            {batchID !== item.id ?
                              <i className="fa fa-square-o" />
                              :
                              <i className="fa fa-check-square-o" />
                            }
                          </td>
                          <td className="col-2" >
                            {item.id}
                          </td>
                          <td className="col-5">
                            {item.title.rendered}
                          </td>
                          <td className="col-3">
                            {item.meta.exam_type}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                }
              </div>
            </div>
          </div>
        </section>
      }


      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Notice </center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Feedback Teacher</td>
                <td className="col-6">
                  <input className="input-common"
                    value={FeedbackTeacher} placeholder="FeedBack Teacher"
                    onChange={(event) => { setFeedbackTeacher(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Feedback Time</td>
                <td className="col-6">
                  <input className="input-common"
                    value={FeedbackTime} placeholder="FeedBack Time"
                    onChange={(event) => { setFeedbackTime(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Feedback Link</td>
                <td className="col-6">
                  <input className="input-common"
                    value={FeedbackLink} placeholder="FeedBack Link"
                    onChange={(event) => { setFeedbackLink(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Notice For All</td>
                <td className="col-6">
                  <CustomButtonUnSelect setData={setFree} Data={Free} DataValue={"All"} />
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td>Course ID</td>
                <td>Course Name</td>
                <td>Category Name</td>
              </tr>
              {VerifiedCourse.map((item, index) => (
                <tr key={index}>
                  <td>{item.course_id}</td>
                  <td>{item.name}</td>
                  <td>{item.category}</td>
                  <td style={{ cursor: "pointer" }} onClick={() => {
                    setCourseIndex(index)
                    setCourseDeleteModal(true)
                  }}>Delete</td>
                </tr>
              ))}
              <tr>
                <td style={{ cursor: "pointer" }} onClick={() => {
                  setAddNewCourse(true)
                }}>Add New</td>
              </tr>
            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditNotice()
                }}
              >
                Send Notice
              </div>
            </div>
          </div>
        </div>
      </div>
      {CourseDeleteModal &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10 shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setCourseDeleteModal(false)
                  }}
                >X</div>
                Do you really want to delete Course for the user?
                <table>
                  <tbody>
                    <tr>
                      <td>Course Name : </td>
                      <td>{VerifiedCourse[CourseDeleteIndex].name}</td>
                    </tr>
                    <tr>
                      <td>Course ID : </td>
                      <td>{VerifiedCourse[CourseDeleteIndex].course_id}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="row" >
                  <div className="col-1 center " />
                  <div className="col-5 center " >
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setCourseDeleteModal(false)
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                  <div className="col-5 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        VerifiedCourse.splice(CourseDeleteIndex, 1)
                        // BatchExamID.splice(BatchExamDeleteIndex, 1)
                        setCourseDeleteModal(false)
                      }}
                    >
                      OK
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }

      {AddNewCourse &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10 shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setAddNewCourse(false)
                  }}
                >X</div>
                <div className="row" style={{ width: "100%", justifyContent: "space-evenly", cursor: "pointer", border: "1px solid #999" }} >
                  <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
                    value={serachCourseName} placeholder="Name"
                    onChange={(event) => {
                      setSearchCourseName(event.target.value)
                      setCourseArray(CourseArray1.filter(a => a.title.rendered.toLowerCase().includes(event.target.value.toLowerCase())))
                    }}
                  />
                </div>

                <div style={{ height: "400px", overflowY: "scroll" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td></td>
                        <td>Course ID</td>
                        <td>Course Name</td>
                        <td>Batch Category</td>
                        <td>Choose</td>
                      </tr>
                      {CourseArray.map((item, index) => (
                        <tr key={index}>
                          <MappingCourse item={item} />
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div style={{ padding: "10px" }}>
                  <div className="row">
                    <div className="col-1" />
                    <div className="col-5 center " >
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setVerifiedCourseCache(VerifiedCourse)
                          // setBatchExamIDCache(BatchExamID)
                          setAddNewCourse(false)
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                    <div className="col-5 center">
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setVerifiedCourse(VerifiedCourseCache)
                          // setBatchExamID(BatchExamIDCache)
                          setAddNewCourse(false)
                        }}
                      >
                        Done
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </section>
      }


      {/* <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Verification History</center></h4>
          <table>
            <tbody>
              <tr>
                <td >Screenshot</td>
                <td >Start Date</td>
                <td >End Date</td>
                <td >Amount Paid</td>
                <td ></td>
              </tr>
              {Object.values(userVerificationHistory).map((item, index) => (
                <tr key={index}>
                  <td >
                    {item.verify_screenshot !== "" &&
                      <a href={item.verify_screenshot} target="_blank">
                        <img src={item.verify_screenshot} style={{ width: "20px", height: "20px" }} />
                      </a>
                    }
                  </td>
                  <td >{item.verify_start_date}</td>
                  <td >{item.verify_end_date}</td>
                  <td >{item.amount_paid}</td>
                  <td
                    onClick={() => {
                      setUserVerificationHistoryModal(true)
                      setEditScreenShot(item.verify_screenshot)
                      setEditStartDate(item.verify_start_date)
                      setEditEndDate(item.verify_end_date)
                      setEditAmountPaid(item.amount_paid)
                      setEditRemarks1(item.verification_remarks_1)
                      setEditRemarks2(item.verification_remarks_2)
                      setEditKey(Object.keys(userVerificationHistory)[index])
                    }}
                  >Edit</td>
                </tr>
              ))}
              <tr>
                <td colSpan={4}
                  onClick={() => {
                    setUserVerificationHistoryModal(true)
                    setEditScreenShot("")
                    setEditStartDate("")
                    setEditEndDate("")
                    setEditAmountPaid("")
                    setEditRemarks1("")
                    setEditRemarks2("")
                    setEditKey(`item - ${ Object.keys(userVerificationHistory).length } `)
                  }}
                >
                  <center><div style={{ width: "100px" }} className="sign-in-button-4"> New</div></center>
                </td>
              </tr>
            </tbody>
          </table>
        </div >
      </div >
      */}

      {userVerificationHistoryModal &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setUserVerificationHistoryModal(false)
                    setEditKey("")
                  }}
                >X</div>
                <table>
                  <tr>
                    <td className="col-4"><b>SN : </b></td>
                    <td className="col-7">
                      {Number(editKey.replace("item-", "")) + 1}
                    </td>
                  </tr>
                  <tr>
                    <td className="col-4"><b>ScreenShot : </b></td>
                    <td className="col-7">
                      <ImageUpload
                        ImageUrl={EditScreenShot}
                        setImageUrl={setEditScreenShot}
                      />
                      {EditScreenShot !== "" &&
                        <img src={EditScreenShot} style={{ height: "200px", width: "200px", objectFit: "contain" }} />
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="col-4"><b>Subscription : </b></td>
                    <td>
                      <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                        onClick={() => {
                          setSubscriptionTime("3 months")
                          dateChangePressed(3)
                        }}
                      >
                        <div className={subscriptionTime == "3 months" ? "active-option" : "passive-option"}>
                          3 months
                        </div>
                      </button>
                      <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                        onClick={() => {
                          setSubscriptionTime("6 months")
                          dateChangePressed(6)
                        }}
                      >
                        <div className={subscriptionTime == "6 months" ? "active-option" : "passive-option"}>
                          6 months
                        </div>
                      </button>
                      <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                        onClick={() => {
                          setSubscriptionTime("1 year")
                          dateChangePressed(12)
                        }}
                      >
                        <div className={subscriptionTime == "1 year" ? "active-option" : "passive-option"}>
                          12 months
                        </div>
                      </button>
                      <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                        onClick={() => {
                          setSubscriptionTime("Custom")
                        }}
                      >
                        <div className={subscriptionTime == "Custom" ? "active-option" : "passive-option"}>
                          Custom
                        </div>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="col-4"><b>Start Date : </b></td>
                    <td className="col-7">
                      {subscriptionTime == "Custom" ?
                        <input type="date"
                          className="p-1 px-2 col-12"
                          value={EditStartDate}
                          onChange={(event) => {
                            setEditStartDate(event.target.value)
                          }}
                          placeholder="Start Date"
                        />
                        :
                        <div>
                          {EditStartDate}
                        </div>
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="col-4"><b>End Date : </b></td>
                    <td className="col-7">
                      {subscriptionTime == "Custom" ?
                        <input type="date"
                          className="p-1 px-2 col-12"
                          value={EditEndDate}
                          onChange={(event) => { setEditEndDate(event.target.value) }}
                          placeholder="End Date"
                        />
                        :
                        <div>
                          {EditEndDate}
                        </div>
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="col-4"><b>Paid Amount : </b></td>
                    <td className="col-7">
                      <input
                        className="p-1 px-2 col-12"
                        value={EditAmountPaid}
                        onChange={(event) => { setEditAmountPaid(event.target.value) }}
                        placeholder="Amount Paid"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="col-4"><b>Remarks : </b></td>
                    <td className="col-7">
                      <input
                        className="p-1 px-2 col-12"
                        value={EditRemarks1}
                        onChange={(event) => { setEditRemarks1(event.target.value) }}
                        placeholder="Remarks"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <center>
                        <div style={{ width: "30%" }} className="sign-in-button-1"
                          onClick={() => {
                            setUserVerificationHistory({
                              ...userVerificationHistory,
                              [editKey]: {
                                'verify_screenshot': EditScreenShot,
                                'verify_start_date': EditStartDate,
                                'verify_end_date': EditEndDate,
                                'amount_paid': EditAmountPaid,
                                'verification_remarks_1': EditRemarks1,
                                'verification_remarks_2': EditRemarks2,
                              }
                            }
                            )
                            setUserVerificationHistoryModal(false)
                          }}
                        >Done</div>
                      </center>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </section>
      }


      {
        reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
    </div >
  )

}
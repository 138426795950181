import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AdsGETApi, AdsGETApiAuth, AdsPOSTApi, ExamBlockPlaceHolder, NodePOSTApiAuth } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import { logout } from '../../../redux/action';
import NotFound from '../../NotFound';
import Chapters from './Chapters';

const slug = {
  "ubt": "UBT",
  "cbt-chemical": "CBT CHEMICAL",
  "cbt-electronics-electricity": "CBT ELECTRONIC ELECTRCITY",
  "cbt-food-related": "CBT FOOD RELATED",
  "cbt-mechinery-molding": "CBT MACHINERY,MOLDING",
  "cbt-metal": "CBT METAL",
  "cbt-pulp-paper-wood": "CBT PULP PAPER WOOD",
  "cbt-rubber": "CBT RUBBER",
  "cbt-textile-sewing": "CBT TEXTILE SEWING"
}
export default function StartCourse() {
  const [AllExamList1, setAllExamList1] = React.useState([]);
  const [AllExamListUnavailable, setAllExamListUnavailable] = React.useState([]);
  const [reload, setReload] = React.useState(false)
  const [pagesLength, setPagesLength] = React.useState("1");
  const [pages, setPages] = React.useState(1);
  const dispatch = useDispatch()
  const [usersData, setUsersData] = React.useState({ meta: { number_of_exams: "0" } });
  const LoginState = useSelector((state) => state.LoginState);
  const [ExamFetch, setExamFetch] = React.useState(false);
  const [NotFoundData, setNotFound] = React.useState(false)
  const [courseData, setCourseData] = React.useState({});
  const [chapterData, setChapterData] = React.useState({});

  const location = useLocation()
  useEffect(() => {
    var slug = location.pathname.slice(slugs.start_course.length + 1);
    setExamFetch(false)
    setNotFound(false)
    setReload(true)

    NodePOSTApiAuth({
      userID: LoginState.userID,
      examSlug: slug
    }, "start-course-details")
      .then((response) => {
        if (response.data.error)
          setNotFound(true)
        else {
          // console.log(response.data.chapters)
          setCourseData(response.data.course[0])
          setChapterData(response.data.chapters)
        }
      })
      .catch(e => {
        if (e.message.includes("Request failed with status code 500"))
          dispatch(logout())
      })
      .finally(() => {
        setExamFetch(true)
        setReload(false)
      })
    console.log(slug)
  }, [])


  return (
    <div style={{ margin: "3px" }}>
      {ExamFetch &&
        <>
          {NotFoundData ?
            <NotFound /> :
            <>
              <div className="row">
                <div className="col-1"></div>
                <div className="col-10 form-view mt-3">

                  <div className="row" style={{ margin: "10px" }}>
                    <h2>Course Information</h2>
                    <div>Course Name: {courseData.title.rendered}</div>

                  </div>
                </div>
              </div>
              <Chapters
                ChapterData={chapterData}
              />
            </>
          }
        </>
      }
      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}


import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { slugs } from '../Constant/slugs';
import { signInImage } from '../Constant/api';

export default function Home() {
  const dispatch = useDispatch()

  return (
    <div style={{ height: "100vh", backgroundColor: "#fff", marginTop: 10 }}>
      <div className="row">
        <div style={{ margin: "10px" }} className="center">
          <img
            src={signInImage}
            style={{ height: "30vh", width: "30vh", objectFit: "contain" }}
          />
        </div>
        <div className="row" >
          <Link to={`${slugs.exam}`} className="col-sm-3 col-6 center" style={{ padding: "20px" }}>
            <img
              className="home-icons"
              src="https://api.widihakwon.com/wp-content/uploads/2024/03/examkoreana.png"
            />
            <div className="home-fonts">
              All Exam
            </div>
          </Link>
          <Link to={`${slugs.courses_list}`} className="col-sm-3  col-6 center" style={{ padding: "20px" }}>
            <img
              className="home-icons"
              src="https://api.widihakwon.com/wp-content/uploads/2024/03/video.png"
            />
            <div className="home-fonts">
              Courses
            </div>
          </Link>
          <Link to={`${slugs.classroom}`} className="col-sm-3 col-6 center" style={{ padding: "20px" }}>
            <img
              className="home-icons"
              src="https://api.widihakwon.com/wp-content/uploads/2024/03/examkoreana.png"
            />
            <div className="home-fonts">
              Class Exam
            </div>
          </Link>
          <Link to={`${slugs.demo_exam}`} className="col-sm-3 col-6 center">
            <img
              className="home-icons"
              src="https://api.widihakwon.com/wp-content/uploads/2024/03/textbook.png"
            />
            <div className="home-fonts">
              Demo Exam
            </div>
          </Link>
          {/* <Link to={`${slugs.book_list_user}/skill-test`} className="col-6 center">
            <img
              className="home-icons"
              src="https://api.widihakwon.com/wp-content/uploads/2024/03/textbook.png"
            />
            <div className="home-fonts">
              Profile
            </div>
          </Link> */}

        </div>

      </div>
    </div>
  )
}
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AdsGETApiAuth, AdsPUTApi } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";


export default function AllExam() {
  const [Projects1, setProjects1] = React.useState([])
  const [Projects2, setProjects2] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [Available, setAvailable] = React.useState(0)
  const [UnAvailable, setUnAvailable] = React.useState(0)
  const [Reload, setReload] = React.useState(false)
  const [ModalQue, setModalQue] = React.useState(false)
  const [deleteUsersModal, setDeleteUsersModal] = React.useState(false)
  const GetAllProjects = (params) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slugs.db_slug_paid_exam)
      .then((res) => {
        setProjects2(res.data)
        setCustomersList(res.data)
        setAvailable(res.data.filter(a => a.meta.available_unavailable == "Available").length)
        setUnAvailable(res.data.filter(a => a.meta.available_unavailable == "Unavailable").length)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }

  const columns = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
      width: "100px"
    },
    {
      id: 31,
      name: "Title",
      selector: (row) => row.title.rendered,
      sortable: true,
      reorder: true,
    },
    {
      id: 32,
      name: "Exam Price",
      selector: (row) => row.meta.exam_price,
      sortable: true,
      reorder: true
    },
    {
      id: 33,
      name: "Availability",
      selector: (row) => row.meta.available_unavailable,
      sortable: true,
      reorder: true,
      width: "150px"
    },
    {
      id: 34,
      name: "Category",
      selector: (row) => row.meta.category_name,
      sortable: true,
      reorder: true
    },
    {
      id: 324,
      name: "Edit by",
      selector: (row) => row.meta.batch_institute_name,
      sortable: true,
      reorder: true
    },
    {
      id: 3,
      name: "View",
      cell: (row) => <Link to={`${slugs.exam_details}${row.slug}`}>View</Link>,
    }
  ];


  const columns2 = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
      width: "100px"
    },
    {
      id: 31,
      name: "Title",
      selector: (row) => row.title.rendered,
      sortable: true,
      reorder: true,
    },
    {
      id: 32,
      name: "Exam Price",
      selector: (row) => row.meta.exam_price,
      sortable: true,
      reorder: true
    },
    {
      id: 33,
      name: "Availability",
      selector: (row) => row.meta.available_unavailable,
      sortable: true,
      reorder: true,
      width: "150px"
    },
    {
      id: 34,
      name: "Type",
      selector: (row) => row.meta.exam_sub_type2,
      sortable: true,
      reorder: true
    },
    {
      id: 34,
      name: "Category",
      selector: (row) => row.meta.exam_sub_type,
      sortable: true,
      reorder: true
    },
    {
      id: 324,
      name: "Edit by",
      selector: (row) => row.meta.batch_institute_name,
      sortable: true,
      reorder: true
    },
    {
      id: 3,
      name: "View",
      cell: (row) => <Link to={`${slugs.exam_details}${row.slug}`}>View</Link>,
    }
  ];
  const location = useLocation()
  const GetProject = (category) => {
    console.log(category)
    if (!category)
      GetAllProjects({
        per_page: 1000,
        _fields: "id,title,slug,date,meta.exam_price,meta.batch_institute_name,meta.exam_time,meta.available_unavailable,meta.category_name,meta.category_id",
        // "filter[meta_query][1][key]": "category_id",
        // "filter[meta_query][1][value][0]": "1053",
        // "filter[meta_query][1][compare]": "!=",
        // "filter[meta_query][relation]": "AND",
      })
    else
      GetAllProjects({
        per_page: 1000,
        _fields: "id,title,slug,date,meta.exam_price,meta.batch_institute_name,meta.exam_time,meta.available_unavailable,meta.category_name,meta.category_id,meta.exam_sub_type,meta.exam_sub_type2",
        // "filter[meta_query][1][key]": "category_id",
        // "filter[meta_query][1][value][0]": "1053",
        // "filter[meta_query][1][compare]": "=",
        // "filter[meta_query][relation]": "AND",
      })
  }
  const history = useHistory()
  useEffect(() => {
    GetAllCategories()
    var type;
    type = location.pathname.slice(slugs.all_exam.length + 1) == "1053"
    setModalQue(type)
    GetProject(type)
    return history.listen((location) => {
      if (location.pathname.includes(slugs.all_exam)) {
        type = location.pathname.slice(slugs.all_exam.length + 1) == "1053"
        window.scrollTo(0, 0)
        GetProject(type)
        setModalQue(type)
      }
    })
    // GetProject()
    // return history.listen((location) => {
    //   if (location.pathname.includes(slugs.all_exam)) {
    //     window.scrollTo(0, 0)
    //     GetProject()
    //   }
    // })
  }, [])
  var type = location.pathname.slice(slugs.all_exam.length + 1) == "" ? "UBT" : location.pathname.slice(slugs.all_exam.length + 1)
  const [choose, setChoose] = React.useState(0)
  const [CategoriesList, setCategoriesList] = React.useState([])
  const GetAllCategories = () => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth({
      per_page: 100,
      _fields: "id,title,slug,meta"
    }, slugs.db_slug_category)
      .then((res) => {
        setCategoriesList(res.data)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  const columnsCategories = [
    {
      id: 1,
      name: "id",
      selector: (row) => row.id,
      sortable: true,
      reorder: true,
      width: "100px"
    },
    {
      id: 31,
      name: "Title",
      selector: (row) => row.title.rendered,
      sortable: true,
      reorder: true,
    },
    {
      id: 342,
      name: "Total Exam",
      selector: (row) => CustomersList.filter(a => a.meta.category_id == row.id).length,
      sortable: true,
      reorder: true
    },
    {
      id: 3,
      name: "Choose",
      right: true,
      cell: (row) => <div style={{ cursor: "pointer" }}
        onClick={() => {
          setProjects2(CustomersList.filter(a => a.meta.category_id == row.id))
          setChoose(0)
        }}
      >
        Choose
      </div>,
    }
  ];

  return (
    <div style={{}}>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>All Exam</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Exams
        </div>
      </div>
      <a href={`${slugs.new_exam}/${type}`}>
        <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
          onClick={() => {
          }}
        >Add New Exam</div>
      </a>
      <div className="row">
        <div style={{ display: "inline", cursor: "pointer" }}>
          <div style={{ display: "inline-block", margin: "0px 5px" }}
            onClick={() => {
              setChoose(0)
              setProjects2(CustomersList)
            }}
          >
            All ({CustomersList.length}) |
          </div>
          <div style={{ display: "inline-block", margin: "0px 5px" }}
            onClick={() => {
              setChoose(0)
              setProjects2(CustomersList.filter(a => a.meta.available_unavailable == "Available"))
            }}
          >
            Available ({Available}) |
          </div>
          <div style={{ display: "inline-block", margin: "0px 5px" }}
            onClick={() => {
              setChoose(0)
              setProjects2(CustomersList.filter(a => a.meta.available_unavailable == "Unavailable"))
            }}
          >
            Unavailable ({UnAvailable}) |
          </div>
          <div style={{ display: "inline-block", margin: "0px 5px" }}
            onClick={() => {
              setChoose(1)
            }}
          >
            Categories ({CategoriesList.length}) |
          </div>
          {/* <div style={{ display: "inline-block", margin: "0px 5px" }}
            onClick={() => {
              setDeleteUsersModal(true)
            }}
          >
            Delete Selected
          </div> */}
        </div>
      </div>

      <div style={{ backgroundColor: "#fff", padding: "10px", width: "98%", margin: "10px", border: "3px solid #eee" }}>
        {choose == 0 &&
          <>
            {!ModalQue ?
              <DataTable
                paginationPerPage={100}
                paginationRowsPerPageOptions={[10, 50, 100, 1000]}
                columns={columns}
                data={Projects2}
                defaultSortFieldId={1}
                defaultSortAsc={false}
                striped
                // sortIcon={<SortIcon />}
                pagination
                selectableRows
                onSelectedRowsChange={(r) => {
                  console.log("asdasd", r)
                  setProjects1(r.selectedRows)
                }}
              />
              :
              <DataTable
                paginationPerPage={100}
                paginationRowsPerPageOptions={[10, 50, 100, 1000]}
                columns={columns2}
                data={Projects2}
                defaultSortFieldId={1}
                defaultSortAsc={false}
                striped
                // sortIcon={<SortIcon />}
                pagination
                selectableRows
                onSelectedRowsChange={(r) => {
                  console.log("asdasd", r)
                  setProjects1(r.selectedRows)
                }}
              />
            }
          </>
        }
        {choose == 1 &&
          <DataTable
            paginationPerPage={100}
            paginationRowsPerPageOptions={[10, 50, 100, 1000]}
            columns={columnsCategories}
            data={CategoriesList}
            defaultSortFieldId={1}
            defaultSortAsc={false}
            striped
            // sortIcon={<SortIcon />}
            pagination
          />
        }
      </div>
      {deleteUsersModal &&
        <section className="modal" >
          <div className="row" style={{ overflow: "hidden" }}>
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setDeleteUsersModal(false)
                  }}
                >X</div>
                Do you really want to Delete all the user?<br />
                <div className="row">
                  <div className="col-3 center " >
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setDeleteUsersModal(false)
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                  <div className="col-3 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        for (let i = 0; i < Projects1.length; i++) {
                          setReload(true)
                          setDeleteUsersModal(false)
                          AdsPUTApi({
                            status: "draft"
                          }, slugs.db_slug_paid_exam, Projects1[i].id)
                            .then((res) => {
                              if (i == Projects1.length - 1) {
                                GetProject()
                              }
                            })
                            .catch((err) => {
                              console.log(err)
                            })

                        }
                      }}
                    >
                      Delete all Exam Exams
                    </div>
                  </div>
                </div>
                <div style={{ backgroundColor: "#fff", padding: "10px", width: "97%", margin: "10px", border: "1px solid #999" }}>
                  <DataTable
                    paginationPerPage={100}
                    paginationRowsPerPageOptions={[10, 50, 100, 1000]}
                    columns={columns}
                    data={Projects1}
                    defaultSortFieldId={1}
                    defaultSortAsc={false}
                    striped
                    // sortIcon={<SortIcon />}
                    pagination
                    selectableRows={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

      }
      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}